<template>
  <!-- hidden PageHeaderWrapper title demo -->
  <div>
    <a-card :body-style="{padding: '24px 32px'}" :bordered="false">
      <a-form-item
        :label="$t('field.deliveryCompanyCode')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'deliveryCompanyCode',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.deliveryCompanyCode') },
                           { max: 100, message: this.$t('field.deliveryCompanyCode')+'长度小于等于100' }]}
          ]"
          name="deliveryCompanyData.deliveryCompanyCode"
          v-model="deliveryCompanyData.deliveryCompanyCode"
          :placeholder="'请输入'+ this.$t('field.deliveryCompanyCode')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.deliveryCompanyName')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'deliveryCompanyName',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.deliveryCompanyName') },
                           { max: 100, message: this.$t('field.deliveryCompanyName')+'长度小于等于100' }]}
          ]"
          name="deliveryCompanyData.deliveryCompanyName"
          v-model="deliveryCompanyData.deliveryCompanyName"
          :placeholder="'请输入'+ this.$t('field.deliveryCompanyName')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.deliveryCompanyPhone')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'deliveryCompanyPhone',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.deliveryCompanyPhone') },
                           { max: 100, message: this.$t('field.deliveryCompanyPhone')+'长度小于等于100' }]}
          ]"
          name="deliveryCompanyData.deliveryCompanyPhone"
          v-model="deliveryCompanyData.deliveryCompanyPhone"
          :placeholder="'请输入'+ this.$t('field.deliveryCompanyPhone')"/>
      </a-form-item>

    </a-card>
  </div>
</template>

<script>
export default {
  name: 'EditDeliveryCompany',
  props: {
    deliveryCompanyData: {
      type: Object
    }
  },
  data () {
    return {
      codeList: {
        'isValid': []
      }
    }
  },
  methods: {}
}

</script>
<style>

</style>

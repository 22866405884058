<template>
  <div class="table-page-search-wrapper">
    <a-form layout="inline">
      <a-row :gutter="24">
        <a-col :md="8" :sm="24">
          <a-form-item
            :label="$t('field.deliveryCompanyName')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-input
              v-model="queryParam.deliveryCompanyName"
              v-decorator="[
                'queryParam.deliveryCompanyName',
                {
                  rules: [
                    { required: true, message: '请输入' + this.$t('field.deliveryCompanyName') },
                    { max: 100, message: this.$t('field.deliveryCompanyName') + '长度小于等于100' }
                  ]
                }
              ]"
              :placeholder="'请输入' + this.$t('field.deliveryCompanyName')"
              name="queryParam.deliveryCompanyName"
            />
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24" v-if="0">
          <a-form-item
            v-if="showAdvancedSearchCondition"
            :label="$t('field.deliveryCompanyPhone')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-input
              v-decorator="[
                'queryParam.deliveryCompanyPhone',
                {
                  rules: [
                    { required: true, message: '请输入' + this.$t('field.deliveryCompanyPhone') },
                    { max: 100, message: this.$t('field.deliveryCompanyPhone') + '长度小于等于100' }
                  ]
                }
              ]"
              name="queryParam.deliveryCompanyPhone"
              v-model="queryParam.deliveryCompanyPhone"
              :placeholder="'请输入' + this.$t('field.deliveryCompanyPhone')"
            />
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24" v-if="0">
          <a-form-item
            v-if="showAdvancedSearchCondition"
            :label="$t('field.status')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
            :required="false"
          >
            <a-input-number
              v-model="queryParam.status"
              v-decorator="[
                'queryParam.status',
                {
                  rules: [
                    { required: true, message: '请输入' + this.$t('field.status') },
                    { min: true, message: this.$t('field.status') + '需大于0' },
                    { max: true, message: this.$t('field.status') + '需小于1000' }
                  ]
                }
              ]"
              :max="100"
              :min="0"
            />
          </a-form-item>
        </a-col>

        <!--        <template v-if="showAdvancedSearchCondition">-->
        <!--          <a-col :md="8" :sm="24">-->
        <!--            <a-form-item label="更新日期">-->
        <!--              <a-date-picker v-model="queryParam.date" style="width: 100%" placeholder="请输入更新日期"/>-->
        <!--            </a-form-item>-->
        <!--          </a-col>-->
        <!--        </template>-->
      </a-row>
      <a-row>
        <a-col :md="24" :sm="24">
          <span
            :style="(showAdvancedSearchCondition && { overflow: 'hidden' }) || {}"
            class="table-page-search-submitButtons"
          >
            <a-button icon="plus" type="primary" @click="$emit('handleAdd')">{{ $t('button.create') }}</a-button>
            <a-divider type="vertical" />
            <a-button icon="search" type="primary" @click="$parent.$parent.$refs.table.refresh(true)">{{
              $t('button.search')
            }}</a-button
            ><a-divider type="vertical" />
            <a-button
              style="margin-left: 8px"
              @click="
                () => {
                  $parent.$parent.queryParam = {}
                  $nextTick(() => {
                    $parent.$parent.$refs.table.refresh(true)
                  })
                }
              "
              >{{ $t('button.reset') }}</a-button
            >
            <a style="margin-left: 8px" @click="toggleAdvancedSearch" v-if="0">
              {{ showAdvancedSearchCondition ? $t('button.collapseIn') : $t('button.collapseOut') }}
              <a-icon :type="showAdvancedSearchCondition ? 'up' : 'down'" />
            </a>
          </span>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  name: 'DeliveryCompanySearch',
  components: {},
  props: {
    // 查询参数
    queryParam: {
      type: Object
    }
  },
  data() {
    return {
      showAdvancedSearchCondition: false,
      codeList: {}
    }
  },
  created() {},
  mounted() {},
  methods: {
    toggleAdvancedSearch() {
      this.showAdvancedSearchCondition = !this.showAdvancedSearchCondition
    },
    resetSearchForm() {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>

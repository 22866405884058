var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-card',{attrs:{"body-style":{padding: '24px 32px'},"bordered":false}},[_c('a-form-item',{attrs:{"label":_vm.$t('field.deliveryCompanyCode'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'deliveryCompanyCode',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.deliveryCompanyCode') },
                         { max: 100, message: this.$t('field.deliveryCompanyCode')+'长度小于等于100' }]}
        ]),expression:"[ 'deliveryCompanyCode',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.deliveryCompanyCode') },\n                         { max: 100, message: this.$t('field.deliveryCompanyCode')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"deliveryCompanyData.deliveryCompanyCode","placeholder":'请输入'+ this.$t('field.deliveryCompanyCode')},model:{value:(_vm.deliveryCompanyData.deliveryCompanyCode),callback:function ($$v) {_vm.$set(_vm.deliveryCompanyData, "deliveryCompanyCode", $$v)},expression:"deliveryCompanyData.deliveryCompanyCode"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.deliveryCompanyName'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'deliveryCompanyName',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.deliveryCompanyName') },
                         { max: 100, message: this.$t('field.deliveryCompanyName')+'长度小于等于100' }]}
        ]),expression:"[ 'deliveryCompanyName',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.deliveryCompanyName') },\n                         { max: 100, message: this.$t('field.deliveryCompanyName')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"deliveryCompanyData.deliveryCompanyName","placeholder":'请输入'+ this.$t('field.deliveryCompanyName')},model:{value:(_vm.deliveryCompanyData.deliveryCompanyName),callback:function ($$v) {_vm.$set(_vm.deliveryCompanyData, "deliveryCompanyName", $$v)},expression:"deliveryCompanyData.deliveryCompanyName"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.deliveryCompanyPhone'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'deliveryCompanyPhone',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.deliveryCompanyPhone') },
                         { max: 100, message: this.$t('field.deliveryCompanyPhone')+'长度小于等于100' }]}
        ]),expression:"[ 'deliveryCompanyPhone',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.deliveryCompanyPhone') },\n                         { max: 100, message: this.$t('field.deliveryCompanyPhone')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"deliveryCompanyData.deliveryCompanyPhone","placeholder":'请输入'+ this.$t('field.deliveryCompanyPhone')},model:{value:(_vm.deliveryCompanyData.deliveryCompanyPhone),callback:function ($$v) {_vm.$set(_vm.deliveryCompanyData, "deliveryCompanyPhone", $$v)},expression:"deliveryCompanyData.deliveryCompanyPhone"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <page-header-wrapper>
    <div class="a-card">
      <DeliveryCompanySearch :queryParam="queryParam" @handleAdd="handleAdd" />
      <div class="table-operator">
        <a-button style="display:none" type="dashed" @click="tableOption"
          >{{ (optionAlertShow && '关闭') || '开启' }} alert
        </a-button>
        <a-dropdown v-action:edit v-if="selectedRowKeys.length > 0">
          <a-menu slot="overlay">
            <a-menu-item class="hide" key="1" click="handleDeleteSelected">
              <a-icon type="delete" />
              删除
            </a-menu-item>
            <!-- lock | unlock -->
          </a-menu>
          <a-button style="margin-left: 8px">
            批量操作
            <a-icon type="down" />
          </a-button>
        </a-dropdown>
      </div>
      <s-table
        :bordered="true"
        ref="table"
        :dataSource="data"
        :data="loadData"
        :alert="options.alert"
        :rowSelection="options.rowSelection"
        :row-key="(record, index) => index"
        :pagination="{ showSizeChanger: true, showQuickJumper: true, pageSize: 20, total: 50 }"
      >
        <a-table-column :title="$t('columnName.remarkNo')" align="center">
          <template slot-scope="text, record, index">{{index+1}}</template>
        </a-table-column>
        <!-- 快递公司编码 -->
        <a-table-column :title="$t('columnName.deliveryCompanyCode')" align="left">
          <template slot-scope="record">{{record.deliveryCompanyCode}}</template>
        </a-table-column>
        <!-- 快递公司名称 -->
        <a-table-column :title="$t('columnName.deliveryCompanyName')" align="left">
          <template slot-scope="record">{{record.deliveryCompanyName}}</template>
        </a-table-column>
        <!-- 创建人 -->
        <a-table-column :title="$t('columnName.creatorName')" align="left">
          <template slot-scope="record">{{record.creatorName}}<br />{{formatDateTime(record.createTime)}}</template>
        </a-table-column>
        <!-- 更新人 -->
        <a-table-column :title="$t('columnName.updaterName')" align="left">
          <template slot-scope="record">{{record.updaterName}}<br />{{formatDateTime(record.updateTime)}}</template>
        </a-table-column>
      </s-table>

      <a-modal
        width="50%"
        :title="$t('page.deliveryCompany')"
        :visible="deliveryCompanyModalShow"
        :confirm-loading="confirmEditLoading"
        @ok="handleEditOk"
        @cancel="handleEditCancel"
      >
        <DeliveryCompanyEdit :deliveryCompanyData="deliveryCompanyData" />
      </a-modal>
      <CommentEditor ref="commentEditor" @handleOk="handleCommentEditOk" />
    </div>
  </page-header-wrapper>
</template>

<script>
// 演示如何使用 this.$dialog 封装 modal 组件
import {
  deleteDeliveryCompany,
  disableDeliveryCompany,
  enableDeliveryCompany,
  getDeliveryCompanyData,
  saveDeliveryCompanyData,
  searchDeliveryCompany
} from '@/api/shipping/deliveryCompany'
import moment from 'moment'
import { STable } from '@/components'

import DeliveryCompanySearch from '@/views/shipping/DeliveryCompanySearch'
import DeliveryCompanyEdit from '@/views/shipping/components/DeliveryCompanyEdit'
import CommentEditor from '@/components/comment/CommentEditor'

export default {
  name: 'DeliveryCompanyList',
  components: {
    CommentEditor,
    STable,
    DeliveryCompanySearch,
    DeliveryCompanyEdit
  },
  data() {
    return {
      confirmEditLoading: false,

      // 查询参数
      queryParam: {},
      data: [],
      status: 'all',
   
      // 加载数据方法 必须为 Promise 对象
      // loadData: parameter => {
      //   console.log('loadData.parameter', parameter)
      //   return getDeliveryCompany(Object.assign(parameter, this.queryParam))
      //       .then(res => {
      //         return res.result
      //       })
      // },
      selectedRowKeys: [],
      selectedRows: [],
      deliveryCompanyModalShow: false,
      deliveryCompanyData: {},
      // custom table alert & rowSelection
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          }
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created() {
    this.tableOption()
    // getRoleList({t: new Date()})
  },
  mounted() {},
  methods: {
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      return searchDeliveryCompany(Object.assign(parameter, this.queryParam)).then(res => {
        console.log('loadData->searchDeliveryCompany.parameter', parameter, res)
        return res
      })
    },
    tableOption() {
      if (!this.optionAlertShow) {
        this.options = {
          alert: {
            show: true,
            clear: () => {
              this.selectedRowKeys = []
            }
          },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },

    handleAdd(record) {
      // 弹出编辑页面
      this.deliveryCompanyData = {}
      this.deliveryCompanyModalShow = true
    },

    handleEdit(record) {
      const that = this
      getDeliveryCompanyData({ deliveryCompanyId: record.deliveryCompanyId })
        .then(res => {
          that.deliveryCompanyData = res
        })
        .catch(res => {
          that.$message.error(this.$t('entity.deliveryCompany.get.fail'))
        })
      // 弹出编辑页面
      this.deliveryCompanyModalShow = true
    },
    handleEditCancel(record) {
      // 弹出编辑页面
      this.deliveryCompanyModalShow = false
    },
    handleEditOk(record) {
      const that = this
      saveDeliveryCompanyData(this.deliveryCompanyData)
        .then(res => {
          that.$message.success(this.$t('entity.deliveryCompany.save.success'))
          this.$refs.table.refresh(true)
        })
        .catch(res => {
          that.$message.error(this.$t('entity.deliveryCompany.save.fail'))
        })
      this.deliveryCompanyData = {}
      // 弹出编辑页面
      this.deliveryCompanyModalShow = false
    },

    handleDelete(record) {
      const that = this
      this.$confirm(this.$t('message.confirm.delete'), this.$t('message.information'), {
        customClass: 'message_wrap',
        closeOnClickModal: false
      }).then(function() {
        return deleteDeliveryCompany(record)
          .then(res => {
            console.log('handleDelete->delete{domain_name}', record, res)
            that.$message.success(this.$t('entity.deliveryCompany.delete.success'))
            return res
          })
          .catch(() => {
            that.$message.error(this.$t('entity.deliveryCompany.delete.fail'))
          })
      })
    },
    handleCommentEditOk(params) {
      const record = params.record
      const that = this
      if (params.type === 'disable') {
        return disableDeliveryCompany(record)
          .then(res => {
            console.log('handleDisable', record, res)
            that.$refs.table.refresh(true)
            that.$message.success('锁定成功')
            return res
          })
          .catch(() => {
            that.$refs.table.refresh(true)
            that.$message.success('解锁失败')
          })
      } else if (params.type === 'enable') {
        return enableDeliveryCompany(record)
          .then(res => {
            console.log('handleEnable', record, res)
            that.$refs.table.refresh(true)
            that.$message.success('解锁定成功')
            return res
          })
          .catch(() => {
            that.$refs.table.refresh(true)
            that.$message.success('解锁失败')
          })
      }
    },
    handleDisable(record) {
      this.$refs['commentEditor'].show(record, 'disable')
    },
    handleEnable(record) {
      this.$refs['commentEditor'].show(record, 'enable')
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },

    resetSearchForm() {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
<style lang="less" scoped>
.ant-avatar-lg {
  width: 48px;
  height: 48px;
  line-height: 48px;
}

.list-content-item {
  color: rgba(0, 0, 0, 0.45);
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  margin-left: 40px;

  span {
    line-height: 20px;
  }

  p {
    margin-top: 4px;
    margin-bottom: 0;
    line-height: 22px;
  }
}
</style>
